import {useState, useEffect} from 'react';
import {FaAngleDown, FaAngleRight} from 'react-icons/fa';
import Button from './components/Button'
import Table from './components/Table'
import Filters from './components/Filters'
import AddEventForm from './components/AddEventForm'

function App() {
  const [events, setEvents] = useState([]);
  const [queryString, setQuery] = useState([]);
  const [page, setPage] = useState(0);
  const [addIsVisible, setAddIsVisible] = useState(false);
  const eventsPerPage = 50;

  const url = 'https://821c-2a01-ab20-0-4-00-71.ngrok.io/api/v1/'

  useEffect(() => {
    const id = setInterval(() => {
      getEvents(queryString);
    }, 60000);

    return () => clearInterval(id);
  });

  useEffect(() => {
    getEvents(queryString);
  }, [queryString]);

  const formatDate = (date) => {
    // we need: YYYY-MM-DD hh:mm:ss
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ':' + seconds;
    return date.getFullYear() + "-" + month + "-" + day + " " + strTime;
  }

  const logLoginClick = () => {
    console.log('login clicked');
  };

  const onAddEventClick = () => {
    setAddIsVisible(!addIsVisible);
  };

  const getEvents = (queryString) => {
    setQuery(queryString);
    fetch(url + 'event/' + queryString)
      .then(result => result.json())
      .then(body => setEvents(body));
  };

  const deleteEvent = (eventId) => {
    console.log('Event to delete: ' + eventId);
    fetch(url + 'event/' + eventId, {method: 'DELETE'})
      .then(result => console.log(result)).then(() => getEvents(queryString));
  };

  const addEvent = (eventFields) => {
    const fromDatetime = formatDate(new Date(`${eventFields.startDate} ${eventFields.startTime}`));
    const toDatetime = formatDate(new Date(`${eventFields.startDate} ${eventFields.endTime}`));

    const eventData = {
      fromDatetime: fromDatetime,
      toDatetime: toDatetime,
      room: eventFields.room,
      department: eventFields.department,
      eventName: eventFields.eventName,
    }

    console.log(eventData);

    fetch(url + 'event/', {
      method: 'POST',
      body: JSON.stringify(eventData),
      headers: {'Content-Type': 'application/json'}
    })
      .then(result => console.log(result)).then(() => getEvents(queryString));
  };

  const submitQuery = (query) => {
    var queryString = ''
    for (const [key, value] of Object.entries(query)) {
      queryString = queryString ? queryString += value ? `&${key}=${value}` : '' : queryString += value ? `?${key}=${value}` : ''
    }
    //console.log(queryString)

    setPage(0);
    getEvents(queryString);
  };

  const computeNumberOfPages = () => {
    var numberOfPages = events.length % eventsPerPage ?
      Math.floor(events.length / eventsPerPage) + 1 : Math.floor(events.length / eventsPerPage);
    if (Object.is(numberOfPages, NaN)) return 0;
    else return numberOfPages;
  };

  const prevPageClick = () => {
    if (page > 0) setPage(page - 1);
  };

  const nextPageClick = () => {
    if (page < computeNumberOfPages() - 1) setPage(page + 1);
  };

  return (
    <div className='container'>
      <div className='head'>
        <Button text={'Login'} onClick={logLoginClick} />
      </div>
      <h1>Hello Backend</h1>
      <div className='add-event-container'>
        {
          addIsVisible ?
            <button className='drop-down-btn' onClick={onAddEventClick}>Add Event<FaAngleDown className='fa-angle' /></button>
            :
            <button className='drop-down-btn' onClick={onAddEventClick}>Add Event<FaAngleRight className='fa-angle' /></button>

        }
        <AddEventForm onSubmitAdd={addEvent} isVisible={addIsVisible} />
      </div>
      <div className='page-button-container'>
        <Button text={'Prev'} onClick={prevPageClick} />
        <p>{page + 1}/{computeNumberOfPages()}</p>
        <Button text={'Next'} onClick={nextPageClick} />
      </div>
      <div className='table-container'>
        <div className='filters'>
          <Filters onSubmitQuery={submitQuery} />
        </div>
        <h2>Number of Results: {events.length}</h2>
        <Table
          content={events.length ? events.slice(page * eventsPerPage, (page + 1) * eventsPerPage) : events}
          getEvents={getEvents}
          queryString={queryString}
          url={url}
          onDeleteEvent={deleteEvent} />
      </div>
      <div className='page-button-container'>
        <Button text={'Prev'} onClick={prevPageClick} />
        <p>{page + 1}/{computeNumberOfPages()}</p>
        <Button text={'Next'} onClick={nextPageClick} />
      </div>
    </div>
  );
}

export default App;
