import {useState, useEffect} from 'react';

const EditEvent = ({isVisible, event, onCancelEdit, getEvents, queryString, url}) => {
  const [thisEvent, setThisEvent] = useState({
    id: '',
    startDate: '',
    from: '',
    to: '',
    department: '',
    eventName: '',
    room: '',
  });

  const formatDate = (date) => {
    // we need: YYYY-MM-DD hh:mm:ss
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ':' + seconds;
    return date.getFullYear() + "-" + month + "-" + day + " " + strTime;
  }

  useEffect(() => setThisEvent({...event}), [event]);

  const submitChange = (e) => {
    e.preventDefault()

    console.log(thisEvent);
    const fromDatetime = formatDate(new Date(`${thisEvent.startDate} ${thisEvent.from}`));
    const toDatetime = formatDate(new Date(`${thisEvent.startDate} ${thisEvent.to}`));

    const eventData = {
      fromDatetime: fromDatetime,
      toDatetime: toDatetime,
      room: thisEvent.room,
      department: thisEvent.department,
      eventName: thisEvent.eventName,
    }

    fetch(url + 'event/' + thisEvent.id, {
      method: 'PUT',
      body: JSON.stringify(eventData),
      headers: {'Content-Type': 'application/json'}
    })
      .then(result => console.log(result)).then(() => getEvents(queryString));
  }

  if (isVisible) {
    return (
      <div className='edit-event'>
        <h1>Edit Event</h1>
        <form className='edit-form' onSubmit={submitChange}>
          <div className='edit-fields'>
            <div className='left'>
              <div className='edit-control'>
                <label>Start Date</label>
                <input type='date' value={thisEvent.startDate}
                  onChange={(e) => setThisEvent({...thisEvent, startDate: e.target.value})}
                  min='2000-01-01' max='2030-01-01'
                  required
                />
              </div>
              <div className='edit-control'>
                <label>From</label>
                <input type='time' value={thisEvent.from}
                  onChange={(e) => setThisEvent({...thisEvent, from: e.target.value})}
                  required
                />
              </div>
              <div className='edit-control'>
                <label>To</label>
                <input type='time' value={thisEvent.to}
                  onChange={(e) => setThisEvent({...thisEvent, to: e.target.value})}
                  required
                />
              </div>
            </div>
            <div className='right'>
              <div className='edit-control'>
                <label>Department</label>
                <input type='text' placeholder='Department' value={thisEvent.department}
                  onChange={(e) => setThisEvent({...thisEvent, department: e.target.value})}
                  required
                />
              </div>
              <div className='edit-control'>
                <label>Event name</label>
                <input type='text' placeholder='Event name' value={thisEvent.eventName}
                  onChange={(e) => setThisEvent({...thisEvent, eventName: e.target.value})}
                  required
                />
              </div>
              <div className='edit-control'>
                <label>Room</label>
                <input type='text' placeholder='Room' value={thisEvent.room}
                  onChange={(e) => setThisEvent({...thisEvent, room: e.target.value})}
                  required
                />
              </div>
            </div>
          </div>
          <input className='submit-btn' type='submit' value='Confirm changes' />
        </form>
        <button className='cancel-btn' onClick={onCancelEdit}>Close</button>
      </div >
    );
  } else {
    return null;
  }
};

export default EditEvent;
