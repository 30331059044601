import {useState} from 'react';

const AddEventForm = ({onSubmitAdd, isVisible}) => {
  const [startDate, setStartDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [department, setDepartment] = useState('');
  const [eventName, setEventName] = useState('');
  const [room, setRoom] = useState('');

  const submitAdd = (e) => {
    e.preventDefault();

    const eventFields = {
      startDate: startDate,
      startTime: startTime,
      endTime: endTime,
      department: department,
      eventName: eventName,
      room: room,
    }

    setStartDate('');
    setStartTime('');
    setEndTime('');
    setDepartment('');
    setEventName('');
    setRoom('');

    onSubmitAdd(eventFields);
  }

  if (isVisible) {
    return (
      <form className='add-form' onSubmit={submitAdd}>
        <div className='column'>
          <div className='add-control'>
            <label>Start Date</label>
            <input type='date' value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              min='2000-01-01' max='2030-01-01' required />
          </div>
          <div className='add-control'>
            <label>Department</label>
            <input type='text' placeholder='Department' value={department}
              onChange={(e) => setDepartment(e.target.value)}
              required />
          </div>
          <div className='add-control'>
            <label>Event Name</label>
            <input type='text' placeholder='Event Name' value={eventName}
              onChange={(e) => setEventName(e.target.value)}
              required />
          </div>
          <div className='add-control'>
            <label>Filter Room</label>
            <input type='text' placeholder='Set Room' value={room}
              onChange={(e) => setRoom(e.target.value)}
              required />
          </div>
          <input className='submit-btn' type='submit' value='Add Event' />
        </div>
        <div className='row'>
          <div className='add-control'>
            <label>Start Time</label>
            <input type='time' value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              required />
          </div>
          <div className='add-control'>
            <label>End Time</label>
            <input type='time' value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              required />
          </div>
        </div>
      </form>
    )
  } else {
    return null;
  }
}

export default AddEventForm;
