import {useState} from 'react';

const Filters = ({onSubmitQuery}) => {
  const [id, setId] = useState('')
  const [department, setDepartment] = useState('')
  const [room, setRoom] = useState('')
  const [name, setName] = useState('')
  const [fromDateQuery, setFromDate] = useState('')
  const [toDateQuery, setToDate] = useState('')

  const submitQuery = (e) => {
    e.preventDefault()

    const queryFields = {
      id: id,
      department: department,
      room: room,
      eventName: name,
      from: fromDateQuery,
      to: toDateQuery,
    }

    onSubmitQuery(queryFields);
  }

  return (
    <form className='filter-form' onSubmit={submitQuery}>
      <div className='search-control'>
        <label>Filter ID</label>
        <input type='text' placeholder='Set ID' value={id} onChange={(e) => setId(e.target.value)} />
      </div>
      <div className='search-control'>
        <label>From</label>
        <input type='date' value={fromDateQuery} onChange={(e) => setFromDate(e.target.value)} />
      </div>
      <div className='search-control'>
        <label>To</label>
        <input type='date' value={toDateQuery} onChange={(e) => setToDate(e.target.value)} />
      </div>
      <div className='search-control'>
        <label>Filter Department</label>
        <input type='text' placeholder='Set Department' value={department} onChange={(e) => setDepartment(e.target.value)} />
      </div>
      <div className='search-control'>
        <label>Filter Event</label>
        <input type='text' placeholder='Set Event Name' value={name} onChange={(e) => setName(e.target.value)} />
      </div>
      <div className='search-control'>
        <label>Filter Room</label>
        <input type='text' placeholder='Set Room' value={room} onChange={(e) => setRoom(e.target.value)} />
      </div>

      <input className='submit-btn' type='submit' value='Search' />
    </form>
  )
}

export default Filters
