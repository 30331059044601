import {useState} from 'react';
import {FaTimes, FaEdit} from 'react-icons/fa';
import EditEvent from './EditEvent';

const Table = ({content, onDeleteEvent, getEvents, queryString, url}) => {
  const [eventToEdit, setEventToEdit] = useState({});
  const [editIsVisible, setEditIsVisible] = useState(false);

  const convertDatetimeForEdit = (datetime) => {
    const NewDatetime = new Date(datetime);
    var date = `${NewDatetime.getFullYear()}-` +
      `${NewDatetime.getMonth() < 9 ? '0' + (NewDatetime.getMonth() + 1) : NewDatetime.getMonth() + 1}-` +
      `${NewDatetime.getDate() < 10 ? '0' + NewDatetime.getDate() : NewDatetime.getDate()}`;
    var time = `${NewDatetime.getHours() < 10 ? '0' + NewDatetime.getHours() : NewDatetime.getHours()}:` +
      `${NewDatetime.getMinutes() < 10 ? '0' + NewDatetime.getMinutes() : NewDatetime.getMinutes()}`
    return {'date': date, 'time': time}
  };

  const convertDatetime = (datetime) => {
    const NewDatetime = new Date(datetime);
    var date = `${NewDatetime.getDate() < 10 ? '0' + NewDatetime.getDate() : NewDatetime.getDate()}.` +
      `${NewDatetime.getMonth() < 9 ? '0' + (NewDatetime.getMonth() + 1) : NewDatetime.getMonth() + 1}.` +
      `${NewDatetime.getFullYear()}`;
    var time = `${NewDatetime.getHours() < 10 ? '0' + NewDatetime.getHours() : NewDatetime.getHours()}:` +
      `${NewDatetime.getMinutes() < 10 ? '0' + NewDatetime.getMinutes() : NewDatetime.getMinutes()}`
    return {'date': date, 'time': time}
  };

  const openEdit = (event) => {
    setEditIsVisible(true);
    setEventToEdit(event);
  }

  const cancelEdit = () => {
    setEditIsVisible(false);
  }

  const editInit = (event) => {
    const loadedEvent = {
      id: event.id,
      startDate: convertDatetimeForEdit(event.fromDatetime).date,
      from: convertDatetimeForEdit(event.fromDatetime).time,
      to: convertDatetimeForEdit(event.toDatetime).time,
      eventName: event.eventName,
      department: event.department,
      room: event.room,
    }

    openEdit(loadedEvent);
  };

  if (content.length > 0) {
    return (
      <div>
        <div>
          <EditEvent isVisible={editIsVisible}
            event={eventToEdit}
            getEvents={getEvents}
            queryString={queryString}
            url={url}
            onCancelEdit={cancelEdit} />
        </div>
        <table className='event-table'>
          <thead>
            <tr>
              <th>ID</th>
              <th>Date</th>
              <th>Time</th>
              <th>Department</th>
              <th>Event</th>
              <th>Room</th>
              <th>
                <div className='delete-column'>
                  <p>Delete</p><p>Edit</p>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {
              content.map((event, i) => (
                <tr key={i}>
                  <td>{event.id}</td>
                  <td>{convertDatetime(event.fromDatetime).date}</td>
                  <td>{convertDatetime(event.fromDatetime).time} - {convertDatetime(event.toDatetime).time}</td>
                  <td>{event.department}</td>
                  <td>{event.eventName}</td>
                  <td>{event.room}</td>
                  <td>
                    <div className='delete-column'>
                      <button onClick={() => onDeleteEvent(event.id)}>
                        <FaTimes style={{color: 'red'}} />
                      </button>
                      <button onClick={() => editInit(event)}>
                        <FaEdit style={{color: 'blue'}} />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    );
  } else {
    return (
      <table className='event-table'>
        <thead>
          <tr>
            <th>ID</th>
            <th>Date</th>
            <th>Time</th>
            <th>Department</th>
            <th>Event</th>
            <th>Room</th>
            <th>Delete</th>
          </tr>
        </thead>
      </table>
    );
  }
};
export default Table
